var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),( _vm.isEmpty)?_c('div',{staticClass:"q-field__messages col q-field--error",staticStyle:{"color":"var(--q-color-negative)"}},[_c('div',{attrs:{"role":"alert"}},[_vm._v("Einfach mit der Maus oder dem Finger im weißen Bereich unterschreiben.")])]):_vm._e(),_c('q-card',{attrs:{"flat":"","bordered":""}},[_c('VueSignaturePad',_vm._b({ref:"signaturePad",attrs:{"height":"300","options":{

        onBegin: function () {
          if (!_vm.inputValue) {
            _vm.signaturePad.resizeCanvas();

          }
        },
        onEnd: function () {
          _vm.saveSignature((_vm.inputValue = _vm.signaturePad.saveSignature().data))
           _vm.isEmpty=false
        }
      }}},'VueSignaturePad',_vm.controlProps,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }