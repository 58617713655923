import { __assign } from "tslib";
import axios from 'axios';
/**
 * Test   -> https://test.api.vpp.get-ag.com/api/vpp2/rest/v1
 * Stage  -> https://staging.api.vpp.get-ag.com/api/vpp2/rest/v1
 * Prod   -> https://api.vpp.get-ag.com/api/vpp2/rest/v1
 */
export var PROXY_BASE_URL = 'https://api.vpp.get-ag.com/api/vpp2/rest/v1';
export var createClient = function (_a) {
    var url = _a.url, apikey = _a.apikey, authToken = _a.authToken;
    return axios.create({
        method: 'GET',
        baseURL: url !== null && url !== void 0 ? url : PROXY_BASE_URL,
        headers: __assign({ 'X-API-KEY': apikey }, (authToken
            ? {
                Authorization: authToken
            }
            : {}))
    });
};
