var fieldsDefinition = {
    gee_acceptPrivacyPolicy: {
        type: 'boolean',
        label: 'Ich habe die Datenschutzerklärung mit den Informationen zur Verarbeitung meiner personenbezogenen Daten des Versorgers Unsere Grüne Glasfaser GmbH & Co. KG zur Kenntnis genommen. ',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    werbeeinwilligung_fax: { type: 'boolean', label: 'per Fax' },
    werbeeinwilligung_telefon: { type: 'boolean', label: 'per Telefon' },
    werbeeinwilligung_email: { type: 'boolean', label: 'per E-Mail' },
    kenntnisnahme_gee: {
        type: 'boolean',
        label: 'Ich gestatte mittels <a href="%url%" target="_blank" >Gestattungserklärung</a> gegenüber UGG die notwendigen Maßnahmen zur Errichtung und zum dauerhaften Betrieb der erforderlichen Netzanlagen.',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    kenntnisnahme_ausfuehrung_vor_widerrufsfrist: {
        type: 'boolean',
        label: 'Ich bin damit einverstanden und verlange ausdrücklich, dass UGG vor Ende der Widerrufsfrist mit der Ausführung der beauftragten Dienstleistung beginnt. Mir ist bekannt, dass ich bei vollständiger Vertragserfüllung durch UGG mein Widerrufsrecht verliere.',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    gee_fn_calendarWidget: {
        label: '',
        type: 'boolean'
    },
    gee_mailRepetition: {
        label: '',
        type: 'boolean'
    },
    gee_birthdayLabel: {
        type: 'text',
        label: ''
    },
    gee_housingUnit: {
        type: 'text',
        label: 'Anzahl Wohneinheiten',
        validation: {
            required: 'dieses Feld kann nicht leer sein',
            regex: ['^([1-9][0-9]{0,2}|999)$', 'dieses Feld darf nur Zahlen von 1 bis 999 beinhalten']
        }
    },
    gee_salutation: {
        type: 'text',
        label: 'Anrede',
        options: [
            { label: 'Herr', value: 'Herr' },
            { label: 'Frau', value: 'Frau' },
            { label: 'Divers', value: 'Divers' },
            { label: 'Firma', value: 'Firma' },
            { label: 'Eheleute', value: 'Eheleute' },
            { label: 'Familie', value: 'Familie' }
        ],
        validation: {
            required: [null, 'deviateOwner']
        }
    },
    gee_title: {
        type: 'text',
        label: 'Titel',
        options: [
            { label: 'Dr.', value: 'Dr.' },
            { label: 'Prof.', value: 'Prof.' },
            { label: 'Prof. Dr.', value: 'Prof. Dr.' }
        ]
    },
    gee_firstName: {
        type: 'text',
        label: 'Vorname',
        validation: {
            required: ['Bitte geben Sie einen Vorname ein.', 'deviateOwner']
        }
    },
    gee_lastName: {
        type: 'text',
        label: 'Nachname',
        validation: {
            required: ['Bitte geben Sie einen Nachname ein.', 'deviateOwner']
        }
    },
    gee_additionalPerson: {
        type: 'text',
        label: 'zusätzliche Person'
    },
    gee_companyName: {
        label: 'Firma',
        type: 'text'
    },
    gee_email: {
        type: 'text',
        label: 'E-Mail',
        validation: {
            required: ['dieses Feld kann nicht leer sein', 'deviateOwner'],
            regex: ['^([a-z0-9_.-]+)@([\\da-z.-]+)\\.([a-z]{2,6})$', 'keine gültige E-Mail']
        }
    },
    gee_emailValidate: {
        type: 'text',
        label: 'E-Mail bestätigen',
        validation: {
            required: [null, 'dieses Feld kann nicht leer sein'],
            regex: ['^([a-z0-9_.-]+)@([\\da-z.-]+)\\.([a-z]{2,6})$', 'keine gültige E-Mail']
        }
    },
    gee_phoneNumber: {
        type: 'text',
        label: 'Telefonnummer',
        validation: {
            required: ['dieses Feld kann nicht leer sein', 'deviateOwner'],
            regex: ['^[0-9]*$', 'dieses Feld darf nur Zahlen beinhalten']
        }
    },
    gee_mobileNumber: {
        type: 'text',
        label: 'Handynummer',
        validation: { regex: ['^[0-9]*$', 'dieses Feld darf nur Zahlen beinhalten'] }
    },
    gee_faxNumber: {
        type: 'text',
        label: 'Faxnummer',
        validation: { regex: ['^[0-9]*$', 'dieses Feld darf nur Zahlen beinhalten'] }
    },
    gee_birthday: {
        type: 'text',
        label: 'Geburtsdatum',
        validation: {
            required: ['Bitte geben Sie Ihr richtiges Geburtsdatum', 'deviateOwner']
        }
    },
    offerMode: {
        label: '',
        type: 'boolean'
    },
    houseNumberInput: {
        label: '',
        type: 'boolean'
    },
    streetInput: {
        label: '',
        type: 'boolean'
    },
    zipCode: {
        label: 'PLZ',
        type: 'text',
        validation: {
            required: [null, 'deviateOwner'],
            regex: ['^\\d{5}$', 'Ungültige Eingabe. Die PLZ muss 5-stellig sein']
        }
    },
    city: {
        label: 'Ort',
        type: 'text',
        validation: {
            required: [null, 'deviateOwner']
        },
        options: []
    },
    street: {
        label: 'Straße',
        type: 'text',
        validation: {
            required: [null, 'deviateOwner']
        },
        options: []
    },
    houseNumber: {
        label: 'Hausnummer',
        type: 'text',
        validation: {
            required: [null, 'deviateOwner']
        }
    },
    addition: {
        label: 'Adresszusatz',
        type: 'text'
    },
    ownerSameAsCustomer: {
        label: 'Eigentümer identisch mit Kunde',
        type: 'boolean'
    },
    ownerSameAsCustomer2: {
        label: 'Eigentümer identisch mit Kunde',
        type: 'boolean'
    },
    telco: {
        label: '',
        type: 'boolean'
    },
    digitalSignature: {
        label: '',
        type: 'signature',
        validation: {
            required: 'bitte Unterschrieben Sie!'
        }
    }
};
var geeDataForm = {
    title: 'Gee Data Form',
    fields: fieldsDefinition,
    initialValues: {
        telco: false,
        streetInput: true,
        houseNumberInput: true,
        offerMode: false
    },
    conditions: {
        deviateOwner: { fieldName: 'ownerSameAsCustomer', type: 'equals', value: false },
        isTelco: { fieldName: 'telco', type: 'equals', value: true },
        isTariff: { fieldName: 'telco', type: 'equals', value: false },
        isCompany: { fieldName: 'gee_salutation', type: 'equals', value: 'Firma' },
        isStreetSelected: { fieldName: 'street', type: 'equals', value: '' },
        isHouseNumberSelected: { fieldName: 'houseNumber', type: 'equals', value: '' },
        isStreetInput: { fieldName: 'streetInput', type: 'equals', value: false },
        isHouseNumberInput: { fieldName: 'houseNumberInput', type: 'equals', value: false },
        showOfferButton: { fieldName: 'offerMode', type: 'equals', value: true }
    },
    structure: [
        {
            type: 'FormDiv',
            props: { class: 'q-mx-sm q-my-md' },
            children: [
                {
                    type: 'FormRow',
                    props: { justify: 'between' },
                    children: [
                        {
                            type: 'FormCol',
                            props: { sm: 8, md: 6 },
                            children: [{ type: 'FormField', name: 'ownerSameAsCustomer', input: 'checkbox' }]
                        },
                        {
                            type: 'FormCol',
                            props: { sm: 4, md: 3 },
                            children: [
                                {
                                    type: 'FormSkipButton',
                                    props: { title: 'weiter ohne GEE', classes: 'full-width', name: 'geeSkipButton' }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'deviateOwner' },
            children: [
                // deviateOwner
                {
                    type: 'FormCard',
                    props: { title: 'Nur durch den Grundstückseigentümer auszufüllen' },
                    children: []
                },
                { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
                {
                    type: 'FormCard',
                    props: { title: 'Persönliche Daten des Grundstückseigentümers' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'gee_salutation',
                                            input: 'select'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'gee_title',
                                            input: 'select',
                                            props: { clearable: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'text',
                                            name: 'gee_firstName'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'text',
                                            name: 'gee_lastName'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'gee_companyName',
                                            input: 'text'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'show_calendarWidget' },
                            children: [
                                {
                                    type: 'FormRow',
                                    props: { justify: 'around' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'gee_birthday',
                                                    input: 'text',
                                                    props: {
                                                        mask: '##.##.####',
                                                        // Remove this to extends date picker
                                                        extends: 'date'
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'show_calendarWidget', revert: true },
                            children: [
                                {
                                    type: 'FormRow',
                                    props: { justify: 'around' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'gee_birthday',
                                                    input: 'text',
                                                    props: {
                                                        mask: '##.##.####'
                                                        // Remove this to extends date picker
                                                        //  extends: 'date'
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
                {
                    type: 'FormCard',
                    props: { title: 'Anschrift des Grundstückseigentümers' },
                    children: [
                        // {
                        //   type: 'FormField',
                        //   input: 'label',
                        //   name: 'installLocationLabel',
                        //   props: { classes: 'text-h6' }
                        // },
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'zipCode',
                                            input: 'text'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'city',
                                            input: 'select',
                                            props: {
                                                filterable: true,
                                                'fill-input': false,
                                                'hide-selected': false,
                                                popupOnOptionsChange: true
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'street',
                                            input: 'select',
                                            props: {
                                                filterable: true,
                                                'fill-input': false,
                                                'hide-selected': false,
                                                popupOnOptionsChange: true
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'houseNumber',
                                            input: 'text'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'addition',
                                            input: 'text'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
                {
                    type: 'FormCard',
                    props: { title: 'Kontaktdaten des Grundstückseigentümers' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around', classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [{ type: 'FormField', input: 'text', name: 'gee_email' }]
                                }
                            ]
                        },
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showMailRepetition' },
                            children: [
                                {
                                    type: 'FormRow',
                                    props: { justify: 'around', classes: 'tr-flex-wrap' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, gutter: ['md', 'x'] },
                                            children: [{ type: 'FormField', input: 'text', name: 'gee_emailValidate' }]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'FormRow',
                            props: { justify: 'around', classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [{ type: 'FormField', input: 'text', name: 'gee_phoneNumber' }]
                                }
                            ]
                        },
                        {
                            type: 'FormRow',
                            props: { justify: 'around', classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [{ type: 'FormField', input: 'text', name: 'gee_faxNumber' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [{ type: 'FormField', input: 'text', name: 'gee_mobileNumber' }]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormCard',
            props: { title: 'Wohneinheiten an der Installationsadresse' },
            children: [
                {
                    type: 'FormCol',
                    props: { xs: 12, gutter: ['md', 'x'] },
                    children: [
                        {
                            type: 'FormField',
                            name: 'gee_housingUnit',
                            input: 'text'
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormCard',
            props: { title: 'Optins des Grundstückseigentümers' },
            children: [
                {
                    type: 'FormCol',
                    props: { xs: 12, md: 8 },
                    children: [
                        {
                            type: 'FormField',
                            input: 'checkbox',
                            name: 'kenntnisnahme_gee',
                            props: { html: true }
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { xs: 12, md: 8 },
                    children: [
                        {
                            type: 'FormField',
                            input: 'checkbox',
                            name: 'kenntnisnahme_ausfuehrung_vor_widerrufsfrist',
                            props: { html: true }
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { xs: 12, md: 8 },
                    children: [
                        {
                            type: 'FormField',
                            input: 'checkbox',
                            name: 'gee_acceptPrivacyPolicy',
                            props: { html: true }
                        }
                    ]
                } /* ,
                {
                  type: 'FormCol',
                  props: { xs: 12, md: 8 },
                  children: [
                    {
                      type: 'FormText',
                      props: {
                        text: 'Ich bin mit der Kontaktaufnahme widerruflich einverstanden, um werbliche Informationen zu einem Glasfaseranschluss, zu Internetdiensten und zu diesbezüglichen Veranstaltungen und Dienstleistungen von Unsere Grüne Glasfaser GmbH & Co. KG und ihren Internetdienste-Partnern zu erhalten. Zu diesem Zweck darf Unsere Grüne Glasfaser mich über die von mir gewählten Kommunikationsmittel (bitte ankreuzen) kontaktieren:',
                        level: 'body2',
                        align: 'justify'
                      }
                    }
                  ]
                },
                {
                  type: 'FormCol',
                  props: { xs: 12 },
                  children: [{ type: 'FormField', input: 'checkbox', name: 'werbeeinwilligung_fax' }]
                },
                {
                  type: 'FormCol',
                  props: { xs: 12 },
                  children: [{ type: 'FormField', input: 'checkbox', name: 'werbeeinwilligung_telefon' }]
                },
                {
                  type: 'FormCol',
                  props: { xs: 12 },
                  children: [{ type: 'FormField', input: 'checkbox', name: 'werbeeinwilligung_email' }]
                } */
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormCard',
            props: { title: 'Unterschrift des Grundstückseigentümers *' },
            children: [
                {
                    type: 'FormDiv',
                    props: { class: 'q-py-md' },
                    children: [{ type: 'FormField', input: 'signature', name: 'digitalSignature' }]
                }
            ]
        },
        {
            type: 'FormDiv',
            props: { class: 'q-mx-md q-my-sm' },
            children: [
                {
                    type: 'FormText',
                    props: { text: 'Pflichtfelder sind mit einem Stern * gekennzeichnet.', level: 'body2' }
                }
            ]
        },
        {
            type: 'FormRow',
            props: { justify: 'between' },
            children: [
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormBackButton',
                            props: { title: 'zurück', classes: 'full-width' }
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showOfferButton', revert: false, transition: false },
                            children: [
                                {
                                    type: 'FormClickButton',
                                    props: { title: 'Angebot erstellen' }
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormSubmitButton',
                            props: { title: 'Weiter', classes: 'full-width' }
                        }
                    ]
                }
            ]
        },
        { type: 'FormLoader', props: { icon: 'ball' } }
    ]
};
export default geeDataForm;
