import { __assign } from "tslib";
var labels = {
    moveInDateError: 'Ihr Einzug darf höchstens #moveInDateDaysInPast# Tage zurück liegen.',
    desiredDateError: 'Bitte geben Sie einen Wunschtermin an.',
    desiredDateError1Year: 'Ihr gewünschter Anschluss kann nur innerhalb eines Jahres erfolgen.',
    desiredDateErrorWeekend: 'Ihr Wunschtermin kann nur an Werktagen erfolgen.',
    desiredDateErrorAfterXDays: 'Ihr Wunschtermin kann frühestens in #requestedDesiredDateDaysInAdvance# Tagen erfolgen.',
    numberPortability: 'Rufnummer(n)',
    PDF_Vorabdruck_Ja: 'Zusammenfassung Ihres Angebotes',
    PDF_Vorabdruck_Nein: 'Vielen Dank für Ihren Auftrag!',
    customerStromNumber: 'Kundennummer beim bisherigen Stromlieferant',
    lastStromPreSupplier: 'Bisheriger Stromlieferant',
    meterStromNumber: 'Stromzählernummer',
    newMeterStromNumber: 'Neue Stromzählernummer',
    newMeterGasNumber: 'Neue Gaszählernummer',
    meterGasNumber: 'Gaszählernummer',
    lastGasPreSupplier: 'Bisheriger Gaslieferant',
    ibanNotFound: 'Ihre IBAN ist nicht korrekt, bitte überprüfen Sie nochmal!',
    ibanNotFoundToShort: 'Ihre IBAN ist noch zu kurz!',
    checkZipCodeAndCity: 'Bitte prüfen Sie Ihre PLZ oder Ort',
    orderDate: 'Bestelldatum',
    backToInputBtnText: 'Zur Eingabeseite',
    cancelBtnText: 'Abbrechen',
    addressTitle: 'Adressdaten',
    installLocationTitle: 'Installationsort/Stockwerk',
    bankTitle: 'Zahlungsweise',
    confirm: 'bestätigen',
    contactTitle: 'Persönliche Daten',
    geeTitle: 'Gestattungserklärung',
    submissionError: 'Fehler beim Versenden',
    submissionSuccess: 'Erfolgreich gesendet',
    summaryTitle: 'Auftragsbestätigung',
    preSupplier: 'Anschlussdaten',
    summaryOfInputTitle: 'Ihr gewählter Tarif',
    salutation: 'Anrede',
    title: 'Titel',
    firstName: 'Vorname',
    lastName: 'Name',
    birthday: 'Geburtsdatum',
    email: 'E-Mail',
    emailLastPage: 'E-Mail',
    contactData: 'Kontaktdaten',
    fax: 'Faxnummer',
    phone: 'Telefonnummer',
    mobil: 'Handynummer',
    billAdress: 'abweichende Rechnungsadresse',
    directDebit: 'Bankeinzug (SEPA-Lastschrift)',
    bankTransfer: 'Überweisung',
    IBAN: 'IBAN',
    BIC: 'BIC',
    Geldinstitut: 'Geldinstitut',
    delieveryAddress: 'Lieferanschrift',
    delieveryAddress2: 'abweichende Lieferadresse',
    sepa: 'SEPA Lastschriftmandat',
    totalprice: 'Gesamtpreis',
    totalpriceunit: '€ / Jahr',
    baseprice: 'Grundpreis',
    basepriceunit: '€ / Jahr',
    workingprice: 'Arbeitspreis',
    workingpriceHT: 'Arbeitspreis HT',
    workingpriceNT: 'Arbeitspreis NT',
    workingpriceunit: 'ct/kWh',
    priceguarantee: 'Preisgarantie',
    contractperiod: 'Vertragslaufzeit',
    cancellationperiod: 'Kündigungfrist',
    saving: 'Ersparnis vs. Vergleichstarif',
    savingunit: '€ / Jahr',
    usageHT: 'Ihr Jahresverbrauch',
    usageHTunit: 'kWh',
    usageNT: 'Ihr Jahresverbrauch Nebenzeit',
    usageNTunit: 'kWh',
    anschlussdaten: 'Ihre Anschlussdaten',
    reason: 'Grund für den Wechsel',
    reasonChangeSupplier: 'Lieferantenwechsel',
    reasonRelocation: 'Umzug',
    presupplier: 'Ihr Vorversorger',
    presuppliername: 'Vorversorgername',
    presuppliernumber: 'bisherige Kundennummer',
    kuendigung_vorversorger: 'Vorversorger gekündigt',
    zaehlernummer: 'bisherige Zählernummer',
    kuendigung_datum: 'Kündigungsdatum',
    postsupplier: 'Neue Anschlussdaten',
    datum_einzug_umzug: 'Einzugsdatum',
    zaehlerstand: 'Zählerstand',
    zaehlerstandHT: 'Zählerstand HT',
    zaehlerstandNT: 'Zählerstand NT',
    newzaehlernummer: 'Zählernummer',
    startOfDelivery: 'Gewünschter Lieferbeginn',
    prekuendigungstext: 'Ich beauftrage ',
    postkuendigungstext: 'mit der Kündigung meines bestehenden Vertrages.',
    kuendigungsdatum: 'zum',
    nextPossibleDateText: 'zum nächstmöglichen Termin.',
    postkuendigungstextNextTime: 'mit der Kündigung meines bestehenden Vertrages',
    billingSameAsDelivery: 'Rechnungsanschrift identisch mit Lieferanschrift',
    secondDeliverySameAsDelivery: 'Lieferadresse identisch mit Lieferanschrift',
    alert: 'Wichtiger Hinweis',
    showDialogBtnText: 'zurück',
    continueBtnText: 'Mit Dateneingabe fortfahren',
    returnToTarifBtnText: 'Zurück zur Tarifübersicht',
    changeUsage: 'Ihre Angaben ändern?',
    changeProduct: 'Produktauswahl ändern',
    returnToInputBody: 'Die Änderung Ihrer Angaben an dieser Stelle hat zur Folge, dass die Tarife erneut berechnet werden, da dies Auswirkungen auf die Tarifverfügbarkeit hat. Sie werden somit erneut zur Eingabeseite geleitet um einen neuen Tarifvergleich zu starten. ',
    dialogText: 'Wenn Sie jetzt zur Tarifübersicht zurückkehren, verlassen Sie die Dateneingabe. Ihre Tarifauswahl und die bereits eingegebenen Daten gehen dadurch verloren. ',
    locationNotFound: 'nicht gefunden',
    tarifInfo: 'Tarifinformation',
    tariffType: 'Zählerart',
    ecoTarif: 'Ökotarif',
    yes: 'ja',
    irrelevant: 'egal',
    customerType: 'Kundenart',
    business: 'Gewerbe',
    private: 'Privat',
    tarifftypeName: 'Tariftyp',
    waermepumpe: 'Wärmepumpe',
    speicherheizung: 'Speicherheizung',
    autoStromGemeinsameMessung: 'Autostrom gemeinsame Messung',
    autoStromGetrennteMessung: 'Autostrom getrennte Messung',
    MaLoIDNotValid: 'Marktlokationsnummer ist nicht gültig',
    MaLoIDToShort: 'Marktlokationsnummer ist zu kurz',
    EmailRepeatNotOK: 'E-Mail und E-Mailwiederholung müssen übereinstimmen',
    birthdayNotValid: 'Bitte geben Sie ein realistisches Datum ein.',
    not18Year: 'Sie müssen mindestens 18 Jahre alt sein.',
    totalPriceOnce: 'Gesamt einmalig',
    basePriceMonthly: 'Gesamt Monatlich',
    basePriceMonthlyUnit: 'Grundpreis € / Monat',
    selectedOptions: 'ausgewählte Optionen',
    internetSpeedUnit: 'Mbit/s',
    EVN: 'Einzelverbindungsnachweis',
    periodOnceSummary: 'Ihre einmaligen Kosten',
    periodMonthlySummary: 'Ihre monatlichen Kosten',
    priceMonthly: 'Gesamt monatlich',
    priceOnce: 'Gesamt einmalig',
    setupCost: 'Aktivierungskosten',
    periodOnce: 'einmalig',
    periodMonthly: 'monatlich',
    backToOptionsBtnText: 'Zurück zur Tarifauswahl',
    optionInfo: 'Produktinformationsblatt (PDF)',
    shippingCosts: 'Versandkosten',
    tariffDetails: 'Tarifdetails',
    productDescription: 'Produktbeschreibung',
    missingProductDescription: 'Dieses Produkt hat keine Beschreibung',
    missingTariffDetails: ' Dieses Produkt hat kein Tarifdetails',
    downloadSpeedLabel: 'Download mit bis zu ',
    uploadSpeedLabel: 'und Upload mit bis zu ',
    newText: 'neue Rufnummer (Sie erhalten die von Ihnen gewünschte Anzahl an Rufnummern)',
    oldText: 'Bestehende Rufnummer(n) portieren',
    totalpriceBeforeBonus: '(vor Abzug aller Boni, inklusive Aufschläge)',
    infinityPeriod: 'auf unbestimmte Zeit',
    priceInfo: 'Preisübersicht',
    yourChoice: 'Ihre Angaben',
    isprepaidtarifffalse: 'nein',
    isprepaidtarifftrue: 'ja',
    location: 'Ort',
    isChangeTariff: 'Tarifwechsel',
    prevTelNumber: 'Format: 06142123456, 06142654321',
    installationLocation: 'Wohnungs-/Briefkastennummer, Installationsort-Stockwerk\'',
    invalidTelNumber: 'Die Vorwahl scheint nicht korrekt zu sein. Erlaubte Zeichen: (+-) und die Ziffern 0-10',
    telNumberToShort: 'Da scheint noch mindestens eine Ziffer zu fehlen.',
    telNumberToLarge: 'Eine Telefonnummer kann insgesamt maximal 20 Ziffern enthalten.'
};
export var electricityLabels = __assign(__assign({}, labels), { supplierTitle: 'Ich wechsle meinen Stromanbieter', customerNumber: 'Kundennummer beim bisherigen Stromlieferant', lastPreSupplier: 'Bisheriger Stromlieferant', meterNumber: 'Stromzählernummer', newMeterNumber: 'Neue Stromzählernummer' });
export var gasLabels = __assign(__assign({}, labels), { supplierTitle: 'Ich wechsle meinen Gasanbieter', newMeterNumber: 'Neue Gaszählernummer', meterNumber: 'Gaszählernummer', customerNumber: 'Kundennummer beim bisherigen Gaslieferant', lastPreSupplier: 'Bisheriger Gaslieferant' });
export default labels;
